import React from 'react'
import { Link } from 'gatsby'
import TTPercent from '../../assets/logo_wide.svg'
import DesktopMenu from './desktopMenu'
import MobileMenu from './mobileMenu'

const Header = () => (
  <div className="header-wrapper">
    <header className="header">
      <div className="logo">
        <Link to="/">
          <TTPercent alt="logo" className="logo__img" />
          <span className="hidden-label">Logo</span>
        </Link>
      </div>
      <DesktopMenu />
      <MobileMenu />
    </header>
  </div>
)

export default Header
