import React from 'react'
import PropTypes from 'prop-types'

const BgBlock = ({ color, backgroundColor, children, bgSrc, id }) => {
  const style = {
    color,
    backgroundColor,
    backgroundImage: `url(${bgSrc})`,
    styilebackgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  }
  return (
    <div className="bg-block" style={style} id={id}>
      {children}
    </div>
  )
}

BgBlock.propTypes = {
  color: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  bgSrc: PropTypes.string,
  id: PropTypes.string,
}

BgBlock.defaultProps = {
  bgSrc: '',
  id: undefined,
}

export default BgBlock
