import React from 'react'
import { Link } from 'gatsby'
import SocialIcons from './socialIcons'

const MenuContent = () => (
  <div className="menu__container">
    <ul className="nav-items">
      <li className="nav-item">
        <Link to="/" activeClassName="active">
          Home
        </Link>
      </li>
      {/* <li className="nav-item">
        <Link to="/#team" activeClassName="active">
          Team
        </Link>
      </li> */}
      <li className="nav-item">
        <Link to="/whitepaper" activeClassName="active">
          Whitepaper
        </Link>
      </li>
      <li className="nav-item">
        <a
          href="https://gleam.io/fEafu/22-save-lives-with-nfts"
          rel="nofollow noopener noreferrer"
          target="_blank"
        >
          Giveaway
        </a>
      </li>
    </ul>
    <SocialIcons
    // after={
    // <Link
    //   to="/mint"
    //   className="button"
    //   style={{ backgroundColor: '#d1304f' }}
    // >
    //   Mint
    // </Link>
    // }
    />
  </div>
)

export default MenuContent
