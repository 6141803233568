import React, { useContext } from 'react'
import classNames from 'classnames'
import MenuContent from './menuContent'
import AppContext from '../../context/context'

const MobileMenu = () => {
  const { mobileNavigationOpen, setMobileNavigationOpen } =
    useContext(AppContext)

  const toggleMenu = () => {
    setMobileNavigationOpen(!mobileNavigationOpen)
  }

  const handleClick = () => {
    toggleMenu()
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      toggleMenu()
    }
  }

  return (
    <nav
      className={classNames(
        'mobile-menu',
        mobileNavigationOpen && 'mobile-menu--open',
      )}
      role="navigation"
    >
      <div
        role="button"
        tabIndex={0}
        className="mobile-menu-toggle"
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        <span />
        <span />
        <span />
      </div>
      <MenuContent />
    </nav>
  )
}

export default MobileMenu
