import React from 'react'
import MenuContent from './menuContent'

const DesktopMenu = () => (
  <nav role="navigation" id="desktop-menu">
    <MenuContent />
  </nav>
)

export default DesktopMenu
