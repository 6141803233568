/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import Twitter from '../assets/twitter.svg'
import Instagram from '../assets/instagram.svg'
import Discord from '../assets/discord.svg'
import Pinterest from '../assets/pinterest.svg'
import Opensea from '../assets/opensea.svg'

export const SOCIAL_LINKS = [
  {
    url: 'https://opensea.io/collection/22-percent',
    icon: (props) => (
      <Opensea alt="Opensea" className="social-icon__image" {...props} />
    ),
  },
  {
    url: 'https://twitter.com/22percent_io',
    icon: (props) => (
      <Twitter alt="Twitter" className="social-icon__image" {...props} />
    ),
  },
  {
    url: 'https://discord.gg/K7rvv5RPHE',
    icon: (props) => (
      <Discord alt="Discord" className="social-icon__image" {...props} />
    ),
  },
  {
    url: 'https://www.instagram.com/invites/contact/?i=1bava380yxb4k&utm_content=na3mwuh',
    icon: (props) => (
      <Instagram alt="Instagram" className="social-icon__image" {...props} />
    ),
  },
  {
    url: 'https://www.pinterest.ch/22percent_io/',
    icon: (props) => (
      <Pinterest alt="Pinterest" className="social-icon__image" {...props} />
    ),
  },
]

// TODO: remove
export const test = false
