import { useEffect } from 'react'
import isBrowser from '../utils'

const useOnScroll = (callback) => {
  useEffect(() => {
    if (isBrowser()) {
      window.addEventListener('scroll', callback, { passive: true })
    }
    return () =>
      isBrowser() &&
      window.removeEventListener('scroll', callback, { passive: true })
  }, [callback])
}

export default useOnScroll
