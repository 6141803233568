/* eslint-disable react/prop-types */
import React from 'react'
import { SOCIAL_LINKS } from '../../content/constants'

const SocialIcons = ({ before, after }) => (
  <div className="social-icons">
    {before}
    {SOCIAL_LINKS.map((socialLink) => (
      <a
        key={socialLink.url}
        rel="nofollow noopener noreferrer"
        href={socialLink.url}
        className="social-icon"
        target="_blank"
      >
        {socialLink.icon()}
        <span className="hidden-label">Social link</span>
      </a>
    ))}
    {after}
  </div>
)

export default SocialIcons
