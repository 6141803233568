import React, { useState, useMemo } from 'react'
// import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'

import '../../styles/main.scss'

import { Helmet } from 'react-helmet'
import classNames from 'classnames'

import useOnScroll from '../../hooks/useOnScroll'
import isBrowser from '../../utils'
import AppContext from '../../context/context'
import Header from './header'
import Footer from './footer'
// import Disclaimer from '../disclaimer/disclaimer'

const Layout = ({
  isHomePage,
  children,
  metaTitle,
  metaDescription,
  className,
}) => {
  const [scrollYPos, setScrollYPos] = useState(0)
  const [hideHeader, setHideHeader] = useState(false)

  const [mobileNavigationOpen, setMobileNavigationOpen] = useState(false)

  const context = useMemo(() => ({
    mobileNavigationOpen,
    setMobileNavigationOpen,
  }))

  const headerHeight = Number(
    isBrowser()
      ? getComputedStyle(document.documentElement)
          .getPropertyValue('--header-height')
          .split('px')[0]
      : 0,
  )

  const calculateShowHeader = () => {
    if (window.scrollY > scrollYPos + headerHeight) {
      setScrollYPos(window.scrollY)
      setHideHeader(true)
    } else if (
      window.scrollY < headerHeight ||
      window.scrollY + headerHeight < scrollYPos
    ) {
      setScrollYPos(window.scrollY)
      setHideHeader(false)
    }
  }

  useOnScroll(calculateShowHeader)

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title={metaTitle}
        meta={[
          {
            name: 'description',
            content: metaDescription,
          },
          {
            name: 'apple-mobile-web-app-capable',
            content: 'yes',
          },
        ]}
      />
      <Helmet>
        <link
          rel="stylesheet"
          type="text/css"
          href="https://staging.creatify.art/css/iframe_inject.css"
        />
        <body
          className={classNames(className, mobileNavigationOpen && 'no-scroll')}
        />
      </Helmet>
      <AppContext.Provider value={context}>
        <script
          id="creatify_script"
          src="https://payments.creatify.art/libs/iframe_inject.js?contract_uuid=be4ac6d7-67f3-48a2-b5a3-816cf24056f6"
        />
        <div
          className={classNames(
            'global-wrapper',
            hideHeader &&
              !mobileNavigationOpen &&
              'global-wrapper--hide-header',
          )}
          data-is-root-path={isHomePage}
        >
          <Header />
          <main>{children}</main>
          {/* <Disclaimer /> */}
          <Footer />
        </div>
      </AppContext.Provider>
    </>
  )
}

Layout.propTypes = {
  isHomePage: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  metaTitle: PropTypes.string.isRequired,
  metaDescription: PropTypes.string.isRequired,
  className: PropTypes.string,
}

Layout.defaultProps = {
  isHomePage: false,
  className: '',
}

export default Layout
