import { Link } from 'gatsby'
import React from 'react'
import TTPercent from '../../assets/logo_wide.svg'
import SocialIcons from './socialIcons'
import NFTCalendarIcon from '../../assets/nftcalendar-svg-white.svg'

const Footer = () => (
  <footer className="footer">
    <div className="logo">
      <Link to="/">
        <TTPercent alt="logo" className="logo__img" />
        <span className="hidden-label">Logo</span>
      </Link>
      {/* <div className="copyright">© {new Date().getFullYear()}, 22%</div> */}
    </div>
    <SocialIcons
      after={
        <a
          rel="nofollow noopener noreferrer"
          href="https://nftcalendar.io/event/22-nft-project/"
          className="social-icon"
          target="_blank"
        >
          <NFTCalendarIcon />
          <span className="hidden-label">Social link</span>
        </a>
      }
    />
  </footer>
)

export default Footer
